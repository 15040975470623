console.log('Loading renderer-epr...');
const { ipcRenderer } = require('electron')
const {
  START_NOTIFICATION_SERVICE,
  NOTIFICATION_SERVICE_STARTED,
  NOTIFICATION_SERVICE_ERROR,
  NOTIFICATION_RECEIVED,
  TOKEN_UPDATED,
} = require ('electron-push-receiver/src/constants');
const event = require('events');
const eprEvent = new event.EventEmitter();

let fcmToken = '';

// Listen for service successfully started
ipcRenderer.on(NOTIFICATION_SERVICE_STARTED, (_, token) => {
  console.debug('[EPR] Notification Service Started');
  fcmToken = token;
  eprEvent.emit('token_update', token);
})

// Handle notification errors
ipcRenderer.on(NOTIFICATION_SERVICE_ERROR, (_, error) => {
  console.log('notification error', error)
})

ipcRenderer.on(TOKEN_UPDATED, (_, token) => {
  eprEvent.emit('token_update', token);
});

ipcRenderer.on(NOTIFICATION_RECEIVED, (_, serverNotificationPayload) => {
  eprEvent.emit('notification', serverNotificationPayload);
});

const getEPRToken = () => {
  return fcmToken;
}

// Retry to send fcm token once, in case of the token is not ready.
setTimeout(() => {
  eprEvent.emit('token_update', fcmToken)
}, 3000)

// Start service
const senderId = '663772314626'
ipcRenderer.send(START_NOTIFICATION_SERVICE, senderId)